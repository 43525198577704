<template>
    <div>
        <b-card>
            <b-navbar>
                <b-navbar-brand tag="h3">
                    {{ this.$t(`modules.systemRecords.ids.${resourceName}`) }}
                </b-navbar-brand>
                <b-button
                    v-if="$can('create system-records')"
                    class="ml-2"
                    variant="primary"
                    style="background-color: #519bd6"
                    @click="openNewRecordModal"
                >
                    {{ this.$t("common.crud.add") }}
                </b-button>
            </b-navbar>
            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: false,
                }"
                :rows="rows"
                :rtl="direction"
                :searchOptions="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :sortOptions="{
                    enabled: true,
                    initialSortBy: { field: 'description', type: 'asc' },
                }"
                :totalRows="dataTableOptions.pagination.totalRows"
                theme="my-theme"
                @on-row-click="onRowClick"
            />
        </b-card>
        <b-modal
            ref="system-record-management-modal"
            hideFooter
            :title="operationLabel"
        >
            <SystemRecordsManageForm
                :operation="currentOperation"
                :resourceName="resourceName"
                :apiSchema="resourceSchema"
                :currentResourceId="selectedRecordId"
                @operationSuccess="onOperationSuccess"
                @operationCancel="toggleModal"
            />
        </b-modal>
    </div>
</template>

<script>
import { BButton, BCard, BNavbar, BNavbarBrand } from "bootstrap-vue"
import store from "@/store/index"
import Api from "@/services/api/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import _ from "lodash"
import SystemRecordsManageForm from "@/views/app/management/system-records/components/SystemRecordsManageForm.vue"
import SchemaService from "@/services/api/schema-service"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BButton,
        BNavbarBrand,
        BNavbar,
        SystemRecordsManageForm,
        BCard,
        Datatable,
    },
    data() {
        return {
            resourceName: null,
            resourcePath: null,
            resourceSchema: null,
            vueFormulateSchema: null,
            dataTableOptions: {
                pagination: {
                    perPage: 50,
                    totalRows: 0,
                },
            },
            isLoading: true,
            dir: false,
            isFilterExpanded: false,
            columns: [],
            rows: [],
            searchTerm: "",
            availableDatatableFilters: {},
            apiFilter: null,
            currentOperation: null,
            selectedRecordId: null,
        }
    },
    computed: {
        operationLabel() {
            return `${this.$t(
                `common.crud.${this.currentOperation}`
            )} ${this.$tc(`modules.systemRecords.ids.${this.resourceName}`)}`
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.resourceName = this.$route.params.name

        const jsonApiResourceSpec = _.get(Api.models, this.resourceName)
        this.resourcePath = _.get(
            jsonApiResourceSpec,
            "options.resourcePath",
            this.resourceName
        )
    },
    async mounted() {
        this.resourceSchema = await SchemaService.getJsonApiSchema(
            this.resourceName
        )
        this.columns = SchemaService.converters.datatable(this.resourceSchema)
        await this.getSystemRecords()
    },
    methods: {
        async getSystemRecords() {
            try {
                this.isLoading = true
                const systemRecordsResponse = await Api.findAll(
                    this.resourceName,
                    {
                        // cache buster url param, in these screens the cache should be ignored.
                        t: Date.now(),
                        // FILTERS
                        filter: this.apiFilter,
                    }
                )

                this.rows = systemRecordsResponse.data
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            } finally {
                this.isLoading = false
            }
        },
        openNewRecordModal() {
            this.currentOperation = "create"
            this.selectedRecordId = null
            this.showModal()
        },
        openEditRecordModal(recordId) {
            this.currentOperation = "edit"
            this.selectedRecordId = recordId
            this.showModal()
        },
        onRowClick(params) {
            this.openEditRecordModal(params.row.id)
        },
        onFilterExpandedChange() {
            this.isFilterExpanded = !this.isFilterExpanded
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
            this.getSystemRecords()
        },
        showModal() {
            this.$refs["system-record-management-modal"].show()
        },
        toggleModal() {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            this.$refs["system-record-management-modal"].toggle()
        },
        onOperationSuccess() {
            this.$toast(toastMessages.getSuccessMessage())
            this.getSystemRecords()
            this.toggleModal()
        },
    },
}
</script>
