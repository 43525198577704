<template>
    <b-overlay :show="loading" rounded="sm">
        <FormulateForm
            v-model="values"
            name="system-record-form"
            :schema="vueFormulateSchema"
            @submit="handleSubmit"
            @validation="getAddressRepository"
        >
            <formulateErrors />

            <b-row class="justify-content-center align-self-center">
                <FormulateInput
                    v-if="$can('edit system-records')"
                    :label="operationLabel"
                    inputClass="btn btn-primary"
                    type="submit"
                />
                <FormulateInput
                    :label="$t('common.crud.close')"
                    inputClass="btn btn-danger"
                    type="button"
                    class="pl-4"
                    @click="emitOperationCancellation"
                />
            </b-row>
        </FormulateForm>
    </b-overlay>
</template>

<script>
import Api from "@/services/api/index"
import SchemaService from "@/services/api/schema-service"
import { BOverlay, BRow } from "bootstrap-vue"
import _ from "lodash"

const OPERATION = {
    CREATE: "create",
    EDIT: "edit",
}

export default {
    components: { BOverlay, BRow },
    props: {
        currentResourceId: {
            type: String,
            required: false,
            default: null,
        },
        resourceName: {
            type: String,
            required: true,
        },
        apiSchema: {
            type: Array,
            required: true,
        },
    },
    emits: ["operationSuccess"],
    data() {
        return {
            currentOperation: OPERATION.CREATE,
            vueFormulateSchema: SchemaService.converters.vueFormulate(
                this.apiSchema
            ),
            loading: false,
            enableCepSearch: false,
            values: {},
        }
    },
    computed: {
        operationLabel() {
            return `${this.$t(`common.crud.${this.currentOperation}`)}`
        },
    },
    mounted() {
        if (this.currentResourceId) {
            this.currentOperation = OPERATION.EDIT
            this.getResourceData()
        }
    },
    methods: {
        async getAddressRepository(event) {
            if (event.hasErrors) return
            if (
                this.currentOperation === OPERATION.EDIT &&
                !this.enableCepSearch
            ) {
                this.enableCepSearch = true
                return
            }

            this.loading = true
            try {
                const response = await Api.findAll("addresses-repository", {
                    filter: {
                        postalCode: this.values.postalCode,
                    },
                })
                if (response.data.length === 0) return

                this.values.addressDescription = response.data[0].street
                this.values.locality = response.data[0].locality
                this.values.state = response.data[0].state
                this.values.neighborhood = response.data[0].neighborhood
                this.values = _.cloneDeep(this.values) // Set reactivity when values is empty
            } finally {
                this.loading = false
            }
        },
        async getResourceData() {
            this.loading = true
            try {
                const result = await Api.find(
                    this.resourceName,
                    this.currentResourceId
                )
                this.values = result.data
            } catch (error) {
                this.$formulate.handle(error, "system-record-form")
            } finally {
                this.loading = false
            }
        },
        async handleSubmit(data) {
            try {
                let result
                if (this.currentOperation === OPERATION.CREATE) {
                    result = await Api.create(this.resourceName, { ...data })
                } else {
                    result = await Api.update(this.resourceName, { ...data })
                }
                this.values = result.data
                this.emitOperationSuccess(result.data)
            } catch (error) {
                this.$formulate.handle(error, "system-record-form")
            }
        },
        /**
         * Emits event to parent component when resource is correctly created or updated
         * @param data
         */
        emitOperationSuccess(data) {
            const eventData = {
                operation: this.currentOperation,
                data,
            }
            this.$emit("operationSuccess", eventData)
        },
        emitOperationCancellation() {
            this.$emit("operationCancel")
        },
    },
}
</script>

<style lang="scss" scoped></style>
