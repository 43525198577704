import Api from "@/services/api"
import _ from "lodash"
import moment from "moment"
import i18n from "@/libs/i18n"

function formatDate(dateString) {
    if (!dateString) {
        return null
    }
    const isoDateStringFormat = "YYYY-MM-DD"
    const momentDate = moment(dateString, isoDateStringFormat, true)

    // if it's a date value, converts accordingly
    if (momentDate.isValid()) {
        return i18n.d(momentDate.toDate(), "date")
    }

    return i18n.d(new Date(dateString), "dateTime")
}
/**
 * Get the schema for a resource from the API
 * @param resourceName
 * @returns {Promise<any>}
 */
async function getJsonApiSchema(resourceName) {
    const jsonApiResourceSpec = _.get(Api.models, resourceName)
    const resourcePath = _.get(
        jsonApiResourceSpec,
        "options.collectionPath",
        resourceName
    )
    const resourceBaseUrl = `${Api.apiUrl}/${resourcePath}`

    const response = await Api.axios.get(`${resourceBaseUrl}/-actions/schema`)

    return response.data
}

/**
 * Convert the schema from the API to the schema for Vue Formulate
 * @param schema {Array} - The schema from the API
 * @returns {Array} - The schema for Vue Formulate
 */
function convertApiSchemaToVueFormulate(schema) {
    const vueFormulateSchema = schema.map(element => {
        const field = {
            label: i18n.t(`common.crud.${element.name}`),
            name: element.name,
            readOnly: element.readOnly,
        }

        if (element.type === "boolean") {
            field.type = "checkbox"
        }

        if (element.type === "number") {
            field.type = "number"
        }

        if (element.type === "date" || element.type === "datetime") {
            field.type = "custom-date"
            field.disabled = element.readOnly
        }
        if (element.name === "postalCode") {
            field.validation = "required|max:8,length|min:8,length"
        }
        return field
    })

    return vueFormulateSchema
}

/**
 * Convert the schema from the API to the schema for Vue Good Table and Datatable Component
 * @param schema {Array} - The schema from the API
 */
function convertApiSchemaToDatatableColumns(schema) {
    const columns = schema.map(element => {
        const column = {
            label: i18n.t(`common.crud.${element.name}`),
            field: element.name,
        }

        if (element.type === "date" || element.type === "datetime") {
            column.formatFn = formatDate
        }
        return column
    })

    return columns
}

export default {
    getJsonApiSchema,
    converters: {
        vueFormulate: convertApiSchemaToVueFormulate,
        datatable: convertApiSchemaToDatatableColumns,
    },
}
