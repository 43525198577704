var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-navbar',[_c('b-navbar-brand',{attrs:{"tag":"h3"}},[_vm._v(" "+_vm._s(this.$t(("modules.systemRecords.ids." + _vm.resourceName)))+" ")]),(_vm.$can('create system-records'))?_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":_vm.openNewRecordModal}},[_vm._v(" "+_vm._s(this.$t("common.crud.add"))+" ")]):_vm._e()],1),_c('datatable',{attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"paginationOptions":{
                enabled: false,
            },"rows":_vm.rows,"rtl":_vm.direction,"searchOptions":{
                enabled: true,
                externalQuery: _vm.searchTerm,
            },"sortOptions":{
                enabled: true,
                initialSortBy: { field: 'description', type: 'asc' },
            },"totalRows":_vm.dataTableOptions.pagination.totalRows,"theme":"my-theme"},on:{"on-row-click":_vm.onRowClick}})],1),_c('b-modal',{ref:"system-record-management-modal",attrs:{"hideFooter":"","title":_vm.operationLabel}},[_c('SystemRecordsManageForm',{attrs:{"operation":_vm.currentOperation,"resourceName":_vm.resourceName,"apiSchema":_vm.resourceSchema,"currentResourceId":_vm.selectedRecordId},on:{"operationSuccess":_vm.onOperationSuccess,"operationCancel":_vm.toggleModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }